<template>
  <popup v-model:show="modelValue"  :before-close="closePop" round>
    <div class="go-content">
      <div class="title">建议前往网页端进行购买,体验更佳</div>
      <div class="link-text dp_f">
        <div class="text">
          <p>网页端访问地址：</p>
          <p>{{ url }}</p>
        </div>
        <div class="copy" @click="copyTextBtn">复制</div>
      </div>
      <div class="foot-text dp_f">
        <div class="btn1 btn" @click="go">继续前往购买</div>
        <div class="btn2 btn" @click="closePop">取消</div>
        </div>
    </div>
  </popup>
</template>

<script setup>
import {toRefs} from "vue";
import {Popup} from "vant";
import {useRouter} from "vue-router";
import {copyText} from "@/utils/tool";

const url = location.host
const router = useRouter()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})
const {modelValue} = toRefs(props)
const emit = defineEmits(['update:modelValue'])
const closePop = ()=>{
  emit('update:modelValue',false)
}
const copyTextBtn=()=>{
  emit('update:modelValue',false)
  copyText(url)
}
const go = ()=>{
  emit('update:modelValue',false)
  router.push('/shop/order/buy/1')
}
</script>
<script >
import { defineComponent } from 'vue';
export default defineComponent({
  name: "goWeb",
});
</script>


<style lang="scss" scoped>
.go-content{
  width: 5.6rem;
  padding: 0.47rem 0.4rem 0.32rem 0.4rem;
  .title{
    font-weight: bold;
    font-size: 0.28rem;
    line-height: 1.4;
  }
  .link-text{
    margin-top: 0.36rem;
    align-items: flex-end !important;

    .text {
      p:nth-child(2) {
        margin-top: 0.22rem;
      }
    }

    .copy {
      color: #3859FF;
      font-size: 0.26rem;
    }
  }
  .foot-text{
    margin-top: 0.5rem;
    color: $mainColor;

    .btn {
      width: 2.24rem;
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
      border-radius: 0.16rem;
      font-size: 0.30rem;
      box-sizing: border-box;

      &.btn1 {
        color: #1C1D1F;
        border: 1px solid #73747F;
      }

      &.btn2 {
        color: #fff;
        background: #3859FF;
      }
    }
  }
}
</style>