import { ossUrl } from "@utils/api.js";

export const headerMenu = [
    {
        name: '标签绑定',
        videoUrl: ossUrl +'/WEB/assets/video/bind.mp4',
        id: 1,
        bgUrl:ossUrl + '/WEB/assets/img/shop/videobg1.png',
        bgUrl2:ossUrl + '/WEB/assets/img/h5/knowMore/bind-bg.png',
    },
    {
        name: '采购入库',
        id: 2,
        bgUrl:ossUrl + '/WEB/assets/img/shop/videobg2.png',
        videoUrl: ossUrl +'/WEB/assets/video/inStock.mp4',
        bgUrl2:ossUrl + '/WEB/assets/img/h5/knowMore/inStock-bg.png',
    },
    {
        name: '仓库间调拨',
        id: 3,
        bgUrl:ossUrl + '/WEB/assets/img/shop/videobg3.png',
        videoUrl:ossUrl + '/WEB/assets/video/bound.mp4',
        bgUrl2:ossUrl + '/WEB/assets/img/h5/knowMore/inBound-bg.png',
    },
    {
        name: '销售出库',
        id: 4,
        bgUrl:ossUrl + '/WEB/assets/img/shop/videobg4.png',
        videoUrl:ossUrl +'/WEB/assets/video/outStock.mp4',
        bgUrl2:ossUrl + '/WEB/assets/img/h5/knowMore/sale-bg.png',
    },
    {
        bgUrl:ossUrl + '/WEB/assets/img/shop/videobg5.png',
        name: '库存盘点',
        id: 5,
        videoUrl: ossUrl +'/WEB/assets/video/check.mp4',
        bgUrl2:ossUrl + '/WEB/assets/img/h5/knowMore/check-bg.png',
    },
    {
        name: '辅助寻货',
        id: 6,
        bgUrl:ossUrl + '/WEB/assets/img/shop/videobg6.png',
        videoUrl: ossUrl +'/WEB/assets/video/getGoods.mp4',
        bgUrl2:ossUrl + '/WEB/assets/img/h5/knowMore/getGoods-bg.png',
    },
]

