<template>
  <teleport to="body">
    <div class="shop-modal animate__animated animate__fadeIn" v-show="modelValue">
      <div class="video-box">
        <el-icon class="close-icon" @click="closeVideo">
          <CircleClose/>
        </el-icon>
        <video :src="videoUrl" loop ref="videoRefs" controls class="video-phone" v-if="type === 'phone'"></video>
        <video :src="videoUrl" loop ref="videoRefs" controls class="video-pc" v-else></video>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import {getCurrentInstance, ref, toRefs, watch} from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  videoUrl: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'phone'
  }
})

const videoRefs = ref(null)
const emit = defineEmits(['update:modelValue'])
const {modelValue, videoUrl, type} = toRefs(props)
const {proxy} = getCurrentInstance();
const closeVideo = () => {
  videoRefs.value.pause()
  emit('update:modelValue', false)
}

watch(() => modelValue.value, (val) => {
  if (val) {
    document.body.style.overflow = 'hidden'
    setTimeout(() => {
      proxy.$refs.videoRefs.play()
    }, 100)
  } else {
    document.body.style.overflow = 'auto'
  }
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "",
});
</script>

<style lang="scss" scoped>
.shop-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  .video-box {
    position: relative;

    .close-icon {
      position: absolute;
      top: -30px;
      right: -30px;
      color: #fff;
      font-size: 32px;
    }
  }

  .video-phone {
    height: 70vh;
    width: calc(70vh * 0.5625);
    border-radius: 8px;
  }
}
</style>
