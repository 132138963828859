<template>
  <div class="page goods-list">
    <h2>其他产品介绍</h2>
    <div class="goods-single" v-for="item in tableData" :key="item.goodsId">
      <img :src="item.imgUrl" alt="位元灵感" class="goods-img"/>
      <div class="goods-name">{{item.title}}</div>
      <template v-if="item.goodsId === 1">
        <ul>
          <li>
            <p class="t">搭载自研PDA应用</p>
            <p class="b">纸盒独家技术，快速高效实现业务流程</p>
          </li>
          <li>
            <p class="t">群读峰值>1000张/秒</p>
            <p class="b">内置R2000/E710芯片，搭载八核处理器</p>
          </li>
          <li>
            <p class="t">0~25米读距可调</p>
            <p class="b">让扫描更加灵活</p>
          </li>
        </ul>
        <div class="img-list dp_f">
            <div class="item">
              <img src="@img/shop/h5/uhd.png" style="width: 0.72rem;height: 0.58rem;" alt="位元灵感" />
              <div class="text">UHF超高频</div>
            </div>
          <div class="item">
              <img src="@img/shop/h5/qrcode.png" style="width: 0.51rem;height: 0.51rem;" alt="位元灵感" />
              <div class="text">二维码扫描</div>
            </div>
          <div class="item">
              <img src="@img/shop/h5/encoded.png" style="width: 0.6rem;height: 0.52rem;" alt="位元灵感" />
              <div class="text">条码扫描</div>
            </div>
        </div>
      </template>
      <template v-else>
        <ul>
          <li>
            <p class="t">铜版纸标签</p>
            <p class="b"> 可粘贴 可打印</p>
          </li>
          <li>
            <p class="t">粘性强 不易脱落</p>
          </li>
          <li>
            <p class="t">读取距离0-12M以上</p>
            <p class="b">根据ZKVC读写器测量结果</p>
          </li>
          <li>
            <p class="t">尺寸54mm*34mm</p>
          </li>
        </ul>
      </template>
        <div class="unit-price">
          <div class="price">￥{{$filters.toYuan(item.price,'商城',true)}}</div>
          <div class="unit">/{{item.unit}}</div>
        </div>
    </div>
    <div class="go-buy"><span @click="showGoWeb = true">去购买</span>{{'>'}}</div>
    <go-web v-model="showGoWeb"/>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {shopGoodsList} from "@apis/shop.js";
import GoWeb from '@views/shop/h5Components/goWeb/index.vue'

const showGoWeb = ref(false)
const tableData = ref([])
const getGoodsList = async () => {
  let res = await shopGoodsList()
  if (res.code !== 0) proxy.$message.error(res.msg)
  tableData.value = res.data.list || []
}
onMounted(() => {
  getGoodsList()
})
</script>

<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" src="./index.scss" scoped>
</style>