<template>
  <div class="main-know">
    <div class="top-content">
      <div class="top-header">
        <shop-header type="other"></shop-header>
      </div>
      <h1 class="page-title">更清楚地了解纸盒</h1>
      <h2 class="sub-title">我们将通过视频和图文教程详细为您介绍</h2>
      <div class="video-content">
        <div class="control">
          <!--          向左-->
          <div class="left" @click="tabChecked2 =  tabChecked2  + 1 > 0? 0 : tabChecked2  + 1 ">
            <el-icon>
              <ArrowLeftBold/>
            </el-icon>
          </div>
          <!--          向右-->
          <div class="right"
               @click="tabChecked2 = tabChecked2 - 1 < - (headerMenu.length - 1 )?  - (headerMenu.length - 1 ): tabChecked2 - 1">
            <el-icon>
              <ArrowRightBold/>
            </el-icon>
          </div>
        </div>
        <div class="video-item" v-for="item in headerMenu" :style="{transform:`translateX(${videoPosition})`}"
             :key="item.id">
          <div class="pic"
               :style="{background:`url(${item.bgUrl}) center center no-repeat`,backgroundSize:'360px 520px'}">
            <div class="title">{{ item.name }}</div>
            <img src="@img/shop/index/play-btn.png" alt="位元灵感" @click="playVideo(item)" class="play-img"/>
          </div>
        </div>
      </div>
    </div>
    <div class="middle-line">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
    <button class="wy-button more-btn primary" @click="knowMore"><span>进一步了解</span></button>
    <!-- 套餐 -->
    <div class="set-meal">
      <h2 class="meal-title">可享7天无理由<span class="bule">退货退款</span>
      </h2>
      <div class="sub-desc">
        按年购买节省<span class="num">16%</span>
      </div>
      <!--      <div class="meal-tab">-->
      <!--        <div class="single" :class="tabChecked === 0?'active':''" @click="tabCheck(0)">每月</div>-->
      <!--        <div class="single" :class="tabChecked === 1?'active':''" @click="tabCheck(1)">每年</div>-->
      <!--        <div class="tab-checked" :style="tabPosition"></div>-->
      <!--      </div>-->
      <div class="meal-list">
        <div class="single">
          <img src="@img/shop/index/is-hot.png" alt="位元灵感" class="is-hot"/>
          <div class="title">通用版</div>
          <div class="sub-title">平均低至</div>
          <div class="price">
            <div class="pirce-content month" :class="tabChecked === 0 ? 'active':''">
              <span class="num ">￥10</span> <span class="line">/</span>天
            </div>
            <div class="pirce-content year" :class="tabChecked === 1 ? 'active':''">
              <span class="num ">￥20</span> <span class="line">/</span>天
            </div>
          </div>
          <div class="sub-text"></div>
          <div class="meal-det">
            <h3>可享权益</h3>
            <div class="list">
              <div class="item">
                <div class="text">账号数量</div>
                <div class="val">无限制</div>
              </div>
              <div class="item">
                <div class="text">仓库数量</div>
                <div class="val">无限制</div>
              </div>
            </div>
          </div>
          <div class="wy-button get-btn primary" @click="buy">申请购买</div>
        </div>
        <div class="single vip">
          <div class="title">定制版</div>
          <div class="vip-text"><span style="margin-right: 20px;">更高效</span><span>更契合</span></div>
          <div class="meal-vip">
            <h3>企业私有部署</h3>
            <div class="item">
              一对一服务
            </div>
            <div class="item">
              全线贴合企业需求
            </div>
            <div class="item">
              功能定制开发
            </div>
          </div>
          <div class="wy-button get-btn vip" @click="showDia = true">联系我们</div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div ref="concatUs" id="concatUs">
      <concat></concat>
    </div>
    <shop-footer></shop-footer>
    <go-top></go-top>
    <ConcatModal v-model="showDia"/>
    <video-player v-model="videoInfo.show" type="phone" :video-url="videoInfo.videoUrl"/>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, reactive, ref} from "vue";
import GoTop from "@views/shop/components/goTop.vue";
import ShopFooter from "@views/shop/components/shopFooter.vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import Concat from "@views/shop/components/concat.vue";
import {shopComboList} from '@utils/api/shop.js';
import {useRoute, useRouter} from "vue-router";
import {headerMenu} from '../config.js';
import VideoPlayer from "@views/shop/components/videoPlayer/index.vue";
import ConcatModal from "@views/shop/components/concatModal.vue";
import useBaseInfo from "@utils/tool/shop/useBaseInfo";

const {goBuy} = useBaseInfo()

const videoPosition = computed(() => {
  let width;
  if (tabChecked2.value === 0) {
    width = 0
  } else if (tabChecked2.value < 0) {
    width = 44 * tabChecked2.value
  } else {
    width = 44 * tabChecked2.value
  }
  return tabChecked2.value * 360 + width + 'px'
})
const videoInfo = reactive({
  videoUrl: '',
  show: false
})
const playVideo = (e) => {
  videoInfo.videoUrl = e.videoUrl
  videoInfo.show = true
}
const showDia = ref(false)
const router = useRouter()
const tabChecked2 = ref(0)
const tableData = ref([])
const route = useRoute();
const concatUs = ref(null)
const tabChecked = ref(0)
const getData = () => {
  shopComboList().then(res => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    tableData.value = res.data.list || []
  })
}
const tabPosition = computed(() => {
  if (tabChecked.value === 0) {
    return {
      left: '4px',
      right: 'unset'
    }
  } else {
    return {
      right: 'unset',
      left: '242px'
    }
  }
})
const tabCheck = (e) => {
  if (e !== tabChecked.value) {
    tabChecked.value = e
  }
}
const {proxy} = getCurrentInstance();

const buy = () => {
  goBuy()
}
onMounted(() => {
  // getData()
  scrollTo()
})
const scrollTo = () => {
  if (route.query.tabLink) {
    let dom = proxy.$refs[route.query.tabLink]
    setTimeout(() => {
      dom.scrollIntoView({
        behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 。默认为 "auto"。
        block: "start", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"。默认为 "start"。
        inline: "center", // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"。默认为 "nearest"
      })
    }, 200)
  } else {
    //滚动到顶部
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
}

//进一步了解
const knowMore = () => {
  router.push({
    path: '/shop/graphicCourse/101',
  })
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "knowMore",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
