<template>
  <div class="page goods-introduce" id="goods-info-introduce">
    <h2>产品套餐介绍</h2>
    <h3 class="save">按年购买可省￥600</h3>
<!--    产品详情列表-->
    <div class="goods-form">
      <div class="item dp_f">
        <div class="key">输入设备使用数量</div>
        <div class="val">
          <mobile-count v-model="countNumber"></mobile-count>
        </div>
      </div>
      <div class="item dp_f">
        <div class="key">选择使用时长</div>
        <div class="val">
          <div class="time-input dp_f" @click="handleShowPicker">
            <div class="value">{{chooseGoods.text}}</div>
            <img src="@img/shop/h5/arrow-down.png" alt="位元灵感" class="input-img"/>
          </div>
        </div>
      </div>
      <div class="item dp_f">
        <div class="key">合计金额</div>
        <div class="val">
          <div class="price">￥{{ $filters.toYuan(chooseGoods.nowPrice, '商城', true) }}</div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <div class="concat-btn" @click="showConcat = true">企业定制版{{" "}}请联系我们</div>
    </div>
  </div>
  <popup v-model:show="showPicker" round position="bottom">
    <picker :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
          v-model="pickChecked"
    ></picker>
  </popup>
  <h5-concat-us v-model="showConcat"/>
</template>

<script setup>
import {getCurrentInstance, ref, watch} from "vue";
import {Picker, Popup} from 'vant';
import H5ConcatUs from "@views/shop/h5Components/h5ConcatUs/index.vue";
import useComboBase from "@utils/tool/shop/useComboBase";
import MobileCount from "@views/shop/components/mobileCount/index.vue";
import {deepClone} from "vant/es/utils/deep-clone";

const {comboList} = useComboBase(() => {
  setColumns()
})
const countNumber = ref(1)
const showConcat = ref(false)
const {proxy} = getCurrentInstance();
const showPicker = ref(false)
const columns = ref([])
const handleShowPicker = () => {
  showPicker.value = true
}
const chooseGoods = ref({})
const onConfirm = () => {
  chooseGoods.value = columns.value.find(item => item.value === pickChecked.value[0])
  showPicker.value = false
};
const tableData = ref([])
const pickChecked = ref([1])

function setColumns() {
  let columnsArr = deepClone(comboList.list)
  columnsArr.forEach(item=>{
    item.text = item.timeTitle
    item.value= item.time
    if(item.time === 12) {
      item.text = `${item.timeTitle}（优惠￥${proxy.$filters.toYuan(item.diffPrice, '元', true)}）`
    }else if(item.time === 24) {
      item.text = `${item.timeTitle}（优惠￥${proxy.$filters.toYuan(item.diffPrice, '元', true)}）`
    }
  })
  columns.value = columnsArr
  chooseGoods.value = columnsArr[0]
}

watch(countNumber, (newValue, oldValue) => {
  if (+newValue === 0 || +oldValue === 0) {
    setColumns()
    return
  }
  const list = columns.value
  list.forEach(item => {
    item.nowPrice = item.nowPrice / oldValue * newValue
    item.diffPrice = item.diffPrice / oldValue * newValue
    if (item.time === 12) {
      item.text = `${item.timeTitle}（优惠￥${proxy.$filters.toYuan(item.diffPrice, '元', true)}）`
    } else if (item.time === 24) {
      item.text = `${item.timeTitle}（优惠￥${proxy.$filters.toYuan(item.diffPrice, '元', true)}）`
    }
  })
})

</script>

<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" src="./index.scss" scoped>

</style>