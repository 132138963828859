<template>
  <div class="h5-know">
    <h5-header type="other"/>
    <video-page />
    <goods-introduce />
    <goods-list />
    <h5-footer/>
    <top-btn/>
  </div>
</template>

<script setup>
import H5Header from "@views/shop/h5Components/h5Header/index.vue";
import VideoPage from './component/videoPage/index.vue'
import GoodsIntroduce from './component/goodsIntroduce/index.vue'
import GoodsList from './component/goodsList/index.vue'
import {addMeta, removeMeta, setRem} from "@utils/tool";
import {useEventListener} from "@utils/tool/event";
import {getCurrentInstance, nextTick, onMounted, onUnmounted} from "vue";
import H5Footer from "@views/shop/h5Components/h5Footer/index.vue";
import {useRoute} from "vue-router";
import TopBtn from "@views/shop/h5Components/topBtn/index.vue";

const route = useRoute()
onMounted(() => {
  setRem()
  nextTick(() => {
    goGoods()
  })
})

function goGoods (){
  if(route.query?.goods){
    let dom = document.getElementById('goods-info-introduce')
    if(dom){
      setTimeout(() => {
        dom.scrollIntoView({
          behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 。默认为 "auto"。
          block: "start", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"。默认为 "start"。
          inline: "center", // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"。默认为 "nearest"
        })
      }, 200)
    }
  }
}
addMeta('viewport',"width=device-width, initial-scale=1",'metaPhone')
useEventListener(window, 'resize', setRem)
const {proxy} = getCurrentInstance();
onUnmounted(() => {
  document.documentElement.style.fontSize = '14px';
  removeMeta('metaPhone')
})
</script>
<script>
import {defineComponent} from "vue";

defineComponent({
  name:'shopKnowPhone'
})
</script>
<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>