<template>
  <div class="page video-page">
     <h2>更清楚的了解纸盒</h2>
     <swiper
    :slidesPerView="1.3"
    :spaceBetween="10"
    :pagination="{
      clickable: true,
    }"
    :loop='true'
    :modules="modules"
    class="video-swiper"
  >
  <swiper-slide v-for="(item,index) in headerMenu" :key="item.id" class="video-swiper-item ">
    <div class="video-content" @click.stop="playVideo(index)" :style="{background: `url(${item.bgUrl2}) center center no-repeat `,backgroundSize:'cover'}">
          <div class="title">{{item.name}}</div>
          <img src="@img/shop/index/play-btn.png"  alt="位元灵感" class="play-img"/>
        </div>
    </swiper-slide>
  </swiper>
  <h5-video-player :video-url="videoUrl" v-model="showVideo"/>
  </div>
</template>

<script setup>
import { ref , computed, onMounted} from "vue";
import { Swiper, SwiperSlide } from 'swiper/swiper-vue';
import 'swiper/modules/pagination.css';
import { Pagination } from 'swiper/modules';
import { headerMenu } from '../../../config'
import H5VideoPlayer from '@views/shop/h5Components/h5VideoPlayer/index.vue'
const swiperRef = ref(null)
import 'swiper/swiper.scss';
const checkSwiper = (e)=>{
  if(swiperRef.value){
    swiperRef.value.swipeTo(e)
  }
}
const modules =  [ Pagination];
const showVideo = ref(false)
const videoUrl = ref('')
const playVideo = (e)=>{
  videoUrl.value = headerMenu[e].videoUrl
  showVideo.value = true
}
</script>

<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" scoped>
.page.video-page{
  padding-top: 1.6rem;
  height: 10.08rem;
  box-sizing: border-box;
  h2{
    text-align: center;
  }
  .video-swiper{
    margin-top: 0.48rem;
    padding-bottom: 0.8rem;
    .video-swiper-item{
      //width: 5rem !important;
      .video-content{
        width: 4.6rem;
        height: 6rem;
        border-radius: 0.24rem;
        background: rgba(0,0,0,0.2);
        position: relative;
        margin: 0 auto;
        .title{
          position: absolute;
          top: 0.64rem;
          left: 0.4rem;
          font-size: 0.6rem;
          font-weight: 600;
          color: #fff;
        }
        .play-img{
          width: 0.8rem;
          height: 0.56rem;
          position: absolute;
          right: 0.4rem;
          bottom: 0.4rem;
        }
      }
    }
    :deep(.swiper-pagination .swiper-pagination-bullet){
      width: 0.32rem;
      height: 0.1rem;
      border-radius: 0.1rem;
      background: #DADCE0;
      opacity: 1;
      @include transitionView(0.1s);
      &.swiper-pagination-bullet-active{
        background: $mainColor;
        width: 0.56rem;
      }
    }
  }
}
</style>