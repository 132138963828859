<template>
  <div class="contact-box">
    <div class="contact-way">
      <div class="title">联系我们</div>
      <div class="way">
        <div class="item">
          <img src="@img/shop/index/wechat.png" alt="位元灵感"/>
          <div class="val">
            <div class="t">企业微信：</div>
            <div class="wechat">
              <img src="@img/shop/index/wechatPic.jpg" alt="位元灵感"/>
              <div class="text">请扫描添加客服微信</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-form">
      <div class="content">
        <div class="form-title">请留下您的联系方式，客服稍后与您联系</div>
        <div class="form-item" :class="tabChecked === 0?'active':''">
          <div class="name">联系电话</div>
          <input type="tel" maxlength="11" class="wy-input" v-model="form.phone" @focus="tabCheck(0)" @blur="blurCheck"
                 placeholder="请输入您的联系电话"/>
        </div>
        <div class="form-item" :class="tabChecked === 1?'active':''">
          <div class="name">微信</div>
          <input type="text" class="wy-input" maxlength="50" v-model="form.weChat" @focus="tabCheck(1)"
                 @blur="blurCheck" placeholder="请输入您的微信号"/>
        </div>
        <div class="form-item" :class="tabChecked === 2?'active':''">
          <div class="name">如何称呼</div>
          <input type="text" class="wy-input" maxlength="50" v-model="form.nickName" @focus="tabCheck(2)"
                 @blur="blurCheck" placeholder="请输入您的姓名"/>
        </div>
        <div class="form-submit">
          <button class="wy-button primary" @click="subMit">提交</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, reactive, ref} from "vue";
import {contactUs} from "@utils/api/shop";
import {ElMessageBox} from "element-plus";

const tabChecked = ref(-1)
const tabCheck = (e) => {
  if (e !== tabChecked.value) tabChecked.value = e;
}
const blurCheck = (e) => {
  tabChecked.value = -1
}
const form = reactive({
  phone: "",
  weChat: "",
  nickName: ""
})
const subMit = () => {
  if (!form.phone) {
    proxy.$message.error('请输入您的联系电话')
    return
  }
  if (!form.nickName) {
    proxy.$message.error('请填写您的称呼')
    return
  }
  //判断手机号是否正确
  if (!(/^1[3456789]\d{9}$/.test(form.phone))) {
    proxy.$message.error('请输入正确的手机号')
    return
  }
  contactUs({
    mobile: form.phone,
    wechat: form.weChat,
    name: form.nickName
  }).then((res) => {
    if (res.code !== 0) return proxy.$message.error(res.msg)
    ElMessageBox.confirm("提交成功！", "提示", {
      confirmButtonText: "确认",
      showCancelButton: false,
      customClass: "zh-message-box shop",
    }).then(() => {
      form.phone = ""
      form.weChat = ""
      form.nickName = ""
    });
  })
}
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "concatUs",
});
</script>
<style lang="scss" scoped>
.contact-box {
  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    .contact-way {
      width: 33%;
    }
    .contact-form {
      flex: auto;
    }
  }

  .contact-way {
    background-color: $mainColor;
    padding: 140px 0 0 8.3vw;
    height: 1000px;
    .title {
      font-size: 48px;
      color: #fff;
      font-weight: 600;
      line-height: 1;
    }
    .way {
      margin-top: 148px;
      .item {
        display: flex;
        align-items: flex-start;

        &:first-of-type {
          margin-bottom: 100px;
        }

        > img {
          width: 24px;
          height: 20px;
          margin-right: 16px;
          position: relative;
          top: -2px
        }

        .val {
          color: #fff;

          .t {
            font-size: 14px;
            line-height: 1;
          }

          .b {
            font-size: 18px;
            font-weight: 600;
            line-height: 1;
            margin-top: 22px;
          }
          .wechat {
            width: 176px;
            height: 176px;
            padding: 6px;
            background-color: #fff;
            border-radius: 8px;
            margin-top: 22px;

            .text {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.6);
              margin-top: 16px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .contact-form {
    background: url("~@img/shop/index/concat-bg.png") center center no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1000px;

    .content {
      width: 674px;

      .form-title {
        font-size: 24px;
        color: #fff;
        margin-bottom: 80px;
      }

      .form-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        padding: 16px 0;

        &:not(:last-of-type) {
          margin-bottom: 70px;
        }

        &.active {
          border-bottom: 1px solid $mainColor;

          > .name {
            color: $mainColor;
          }
        }

        > .name {
          color: #fff;
          width: 150px;
          font-size: 18px;
        }

        .wy-input {
          font-size: 18px;
          flex: auto;

          &::placeholder {
            color: #FEFEFE !important;
            opacity: 0.2;
          }
        }
      }

      .form-submit {
        text-align: right;

        button {
          width: 200px;
          height: 56px;
          line-height: 56px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>