<template>
  <div class="count-number animate__animated animate__fadeIn">
    <div class="count-btn-item" :class="modelValue <= min?'disabled':''" @click="handleReduce">-</div>
    <el-input  v-elInput class="count-input no-number" maxlength="5" v-number v-bind="attrs"
              :model-value="modelValue" @update:modelValue="changeCount"></el-input>
    <div class="count-btn-item" @click="handleAdd">+</div>
  </div>
</template>

<script setup>
import {toRefs, useAttrs} from "vue";
//modelValue 可以是数字或者字符串
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: 0
  },
  max: {
    type: Number,
    default: 100
  },
  min: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(['update:modelValue'])
const {modelValue, max, min} = toRefs(props)
const attrs = useAttrs()
const handleReduce = () => {
  let number = +modelValue.value
  if (number > min.value) {
    emit('update:modelValue', number - 1)
  }
}
const handleAdd = () => {
  let number = +modelValue.value
  if (number >= max.value) return
  emit('update:modelValue', number + 1)
}
const changeCount = (val) => {
  if (!val) {
    emit('update:modelValue', min.value)
  }
  let number = min.value;
  if (+val < number) {
    emit('update:modelValue', number)
  } else {
    if (+val >= max.value) {
      emit('update:modelValue', max.value)
      return
    }
    emit('update:modelValue', +val)
  }
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "countNumber"
});
</script>

<style lang="scss" scoped>
.count-number {
  display: flex;
  align-items: center;

  .count-btn-item {
    width: 0.3rem;
    height: 0.3rem;
    line-height: 0.3rem;
    text-align: center;
    cursor: pointer;
    user-select: none;
    font-size: 0.3rem;

    &.disabled {
      color: #ccc;
      cursor: not-allowed;
    }
  }

  .count-input {
    width: 1.2rem;
    height: 0.8rem;
    margin: 0 0.16rem;

    :deep(.el-input__wrapper) {
      border: 1px solid #797A80;
      background-color: transparent;
    }
    :deep(.el-input__inner) {
      text-align: center;
      color: #1C1D1F;
      font-size: 0.3rem;
    }

  }
}

</style>
